<template>
    <v-container fluid class="p-4" style="padding-bottom:150px !important">
        <v-row>
            <v-col cols="12">
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </div>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col cols="12">
                <v-container fluid class="ml-5">
                    <v-row>
                        <v-col class="col-12" sm="6" md="2">
                            <p class="blue-lcd mb-1">Region</p>   
                            <v-autocomplete
                                dense
                                solo
                                clearable
                                v-model="region"
                                :items="regions"
                                item-value="region"
                                item-text="region"
                                class="ma-0 pa-0 border-12"
                                @change="(event) => getOffice(event)"
                                hide-details=true
                                :disabled="disabled_region"
                            ></v-autocomplete> 
                        </v-col>
                        <v-col class="col-12" sm="6" md="2">
                            <p class="blue-lcd mb-1">Office</p>   
                            <v-autocomplete
                                dense
                                solo
                                clearable
                                v-model="office"
                                :items="offices"
                                item-value="office_id"
                                item-text="office"
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                                @change="(event) => setOfficeName(event)"
                                :disabled="disabled_office"
                            ></v-autocomplete> 
                        </v-col>

                        <v-col cols="12" xs="12" sm="6" md="2">
                            <p class="blue-lcd mb-1">From Period</p>   
                            <v-menu
                                ref="modal"
                                v-model="modal"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    clearable
                                    solo
                                    dense
                                    v-model="date"
                                    persistent-hint
                                    append-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date"
                                    type="month"
                                    no-title
                                    @input="modal = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" xs="12" sm="6" md="2">
                            <p class="blue-lcd mb-1">To Period</p>   
                            <v-menu
                                ref="modal_to"
                                v-model="modal_to"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    solo
                                    dense
                                    v-model="date_to"
                                    persistent-hint
                                    append-icon="mdi-calendar"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    clearable
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date_to"
                                    type="month"
                                    no-title
                                    @input="modal_to = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col class="col-12" sm="6" md="2">
                            <p class="blue-lcd mb-1">Bagian</p>   
                            <v-autocomplete
                                dense
                                solo
                                clearable
                                v-model="bagian"
                                :items="bagians"
                                item-value="bagian"
                                item-text="nama_bagian"
                                class="ma-0 pa-0 border-12"
                                hide-details=true
                            ></v-autocomplete> 
                        </v-col>

                        <v-col cols="12" xs="12" sm="6" md="2">
                            <div class="d-flex mt-6">
                                <v-btn class="rounded-l text-white" color="primary" @click="getKpiAdmin()">
                                    Search
                                </v-btn>
                            </div>
                        </v-col>
                        
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12">
                <v-tabs
                    v-model="tab"
                    color="#1565c0"
                    class="round_top"
                    icons-and-text
                    style="width: fit-content;margin-left: 25px;"
                >
                    <v-tabs-slider color="primary"></v-tabs-slider>

                    <v-tab href="#tab-1" active-class="active-tab">
                        Chart
                    </v-tab>

                    <v-tab href="#tab-2" active-class="active-tab">
                        Datatable
                    </v-tab>

                </v-tabs>
            </v-col>
            <v-tabs-items v-model="tab">
                <v-tab-item
                    value="tab-1"
                >
                    <v-container fluid fluid class="pt-0 mt-0">
                        <v-row>
                            <v-col cols="12">
                                <v-card class="rounded-l elevation-5">
                                    <v-card-title>
                                        Result
                                        <v-spacer></v-spacer>
                                        <v-btn
                                        small
                                        color="#fff"
                                        class="py-5 mr-2"
                                        @click="getKpiAdmin()"
                                        >
                                            <v-icon>mdi-cached</v-icon>
                                        </v-btn>
                                        <!-- <v-btn
                                        small
                                        color="#fff"
                                        class="py-5 mr-2"
                                        @click="dialog = true"
                                        >
                                            <v-icon>mdi-filter-variant</v-icon>
                                        </v-btn> -->
                                        
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container fluid class="fill-height" fluid>
                                            <v-row>
                                                <v-col
                                                    xs="12" 
                                                    sm="12" 
                                                    md="12"
                                                >
                                                    <v-card class="pa-md-5">  
                                                        <h5 style="font-weight: 900;">Admin Penjualan {{labelText}}</h5>
                                                        <div id="container1" style="height: 370px; width: 100%;"></div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-5">
                                                <v-col
                                                    xs="12" 
                                                    sm="12" 
                                                    md="12"
                                                >
                                                    <v-card class="pa-md-5">  
                                                        <h5 style="font-weight: 900;">Admin Keuangan {{labelText}}</h5>
                                                        <div id="container2" style="height: 370px; width: 100%;"></div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row class="mt-5">
                                                <v-col
                                                    xs="12" 
                                                    sm="12" 
                                                    md="12"
                                                >
                                                    <v-card class="pa-md-5">  
                                                        <h5 style="font-weight: 900;">Admin Gudang {{labelText}}</h5>
                                                        <div id="container3" style="height: 370px; width: 100%;"></div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                                <v-dialog
                                    v-model="dialog"
                                    max-width="700px"
                                >
                                    <v-card class="p-2 rounded-l">
                                        <v-card-title class="text-center">
                                            <v-row>
                                                <v-col cols="12">
                                                    Filter KPI Achive Admin
                                                </v-col>
                                                <v-divider></v-divider>
                                            </v-row>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container fluid class="mt-2 mb-5">
                                                <v-row  justify="center">
                                                    <v-col class="col-12" sm="6" md="4">
                                                        <p class="blue-lcd mb-1">Region</p>   
                                                        <v-autocomplete
                                                            dense
                                                            solo
                                                            clearable
                                                            v-model="region"
                                                            :items="regions"
                                                            item-value="region"
                                                            item-text="region"
                                                            class="ma-0 pa-0 border-12"
                                                            @change="(event) => getOffice(event)"
                                                            hide-details=true
                                                        ></v-autocomplete> 
                                                    </v-col>
                                                    <v-col class="col-12" sm="6" md="6">
                                                        <p class="blue-lcd mb-1">Office</p>   
                                                        <v-autocomplete
                                                            dense
                                                            solo
                                                            clearable
                                                            v-model="office"
                                                            :items="offices"
                                                            item-value="office_id"
                                                            item-text="office"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                        ></v-autocomplete> 
                                                    </v-col>
                                                </v-row>
                                                <v-row justify="center">
                                                    <v-col cols="12" xs="12" sm="6" md="4">
                                                        <p class="blue-lcd mb-1">Tahun</p>   
                                                        <v-autocomplete
                                                            dense
                                                            solo
                                                            clearable
                                                            v-model="tahun"
                                                            :items="tahuns"
                                                            item-value="tahun"
                                                            item-text="tahun"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                        ></v-autocomplete>
                                                    </v-col>
                                                    <v-col class="col-12" sm="6" md="6">
                                                        <p class="blue-lcd mb-1">Bagian</p>   
                                                        <v-autocomplete
                                                            dense
                                                            solo
                                                            clearable
                                                            v-model="bagian"
                                                            :items="bagians"
                                                            item-value="bagian"
                                                            item-text="nama_bagian"
                                                            class="ma-0 pa-0 border-12"
                                                            hide-details=true
                                                        ></v-autocomplete> 
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions class="text-center">
                                            <v-row justify="center" align="center">
                                                <v-col cols="12" xs="12" sm="3" md="3">
                                                    <v-btn block class="rounded-l mr-2" color="#fff" @click="clear()">Clear</v-btn>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="3" md="3">
                                                    <v-btn block class="rounded-l text-white" color="primary" @click="getKpiAdmin()">
                                                        Save
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item
                    value="tab-2"
                >
                    <v-container fluid class="pt-0 mt-0">
                        <v-row>
                            <v-col cols="12">
                                <v-card class="rounded-l elevation-5">
                                    <v-card-title>
                                        Result
                                        <v-spacer></v-spacer>
                                        
                                        <v-btn
                                            small
                                            color="#fff"
                                            class="py-5 mr-2"
                                        >
                                            <v-icon>mdi-file-excel</v-icon>
                                            <download-excel class="text" :fetch="exportExcel" :fields="headersColumn"
                                                :before-generate="startDownload" :before-finish="finishDownload">
                                            </download-excel>
                                        </v-btn>

                                        <v-btn
                                        small
                                        color="#fff"
                                        class="py-5 mr-2"
                                        @click="getKpiAdmin()"
                                        >
                                            <v-icon>mdi-cached</v-icon>
                                        </v-btn>
                                        <!-- <v-btn
                                        small
                                        color="#fff"
                                        class="py-5 mr-2"
                                        @click="dialog = true"
                                        >
                                            <v-icon>mdi-filter-variant</v-icon>
                                        </v-btn> -->
                                        
                                    </v-card-title>
                                    <v-card-text>
                                        <v-data-table
                                            :headers="headers"
                                            :items="kpi_admins"
                                            group-by="nama_bagian"
                                            class="elevation-1"
                                            show-group-by
                                        >
                                            <template v-slot:[`item.total`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.total) }}
                                            </template>
                                            <template v-slot:[`item.Month`]="{ item }">
                                                {{ monthNumToName(parseInt(item.Month)) }}
                                            </template>
                                            <template v-slot:[`item.action`]="{ item }">
                                                <v-menu
                                                    bottom
                                                    left
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            dark
                                                            icon
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            color="grey"
                                                        >
                                                            <v-icon>mdi-dots-vertical</v-icon>
                                                        </v-btn>
                                                    </template>

                                                    <v-list dense>
                                                        <v-list-item @click="getDetailItem(item)">
                                                            <v-list-item-icon>
                                                                <v-icon>mdi-magnify</v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title>Detail</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </template>
                                        </v-data-table>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
            </v-tabs-items> 
            <v-dialog
                v-model="dialogDetail"
                max-width="900px"
            >
                <v-card class="p-2 rounded-l">
                    <v-card-title class="text-center">
                        <v-row>
                            <v-col cols="12">
                                Detail KPI
                            </v-col>
                            <v-divider></v-divider>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table
                            :headers="header_details"
                            :items="kpi_details"
                            group-by="region"
                            class="elevation-1"
                            :loading="loading"
                            show-group-by
                        >
                            <template v-slot:[`item.total`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.total) }}
                            </template>
                            <template v-slot:[`item.Month`]="{ item }">
                                {{ monthNumToName(parseInt(item.Month)) }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data() {
        return {
            tab: 'tab-1',
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Kencana Maju Bersama',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'KPI',
                disabled: true,
                },
                {
                text: 'KPI Achive Admin',
                disabled: true,
                href: '',
                }
            ],
            labelText:'',
            disabled_region: false,
            disabled_office: false,
            loading: false,
            display: 'none',
            dialog: false,
            regions: [],
            region: '',
            offices: [],
            office: '',     
            modal: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
            modal_to: false,
            date_to: '',
            bagians: [],
            bagian: '',
            show: true,
            tahuns: [],
            tahun: '',
            months: [
                'Jan', 'Feb', 'Mar', 'Apr', 'May',
                'Jun', 'Jul', 'Aug', 'Sep',
                'Oct', 'Nov', 'Dec'
            ],
            x: window.matchMedia("(max-width: 991px)"),
            admin_penjualans: [],
            admin_penjualan_databases: [],
            mean_admin_penjualan_databases: [],
            admin_penjualan_surat_pesanans: [],
            mean_admin_penjualan_surat_pesanans: [],
            admin_penjualan_items: [],
            mean_admin_penjualan_items: [],
            admin_penjualan_surat_jalans: [],
            mean_admin_penjualan_surat_jalans: [],
            admin_penjualan_confirm_orders: [],
            mean_admin_penjualan_confirm_orders: [],
            admin_keuangans: [],
            admin_keuangan_lunas: [],
            admin_keuangan_rupiahs: [],
            admin_keuangan_kuitansi: [],
            admin_keuangan_tanda_terimas: [],
            admin_keuangan_faktur_collectors: [],
            admin_keuangan_fakturs: [],
            mean_admin_keuangan_lunas: [],
            mean_admin_keuangan_rupiahs: [],
            mean_admin_keuangan_kuitansi: [],
            mean_admin_keuangan_tanda_terimas: [],
            mean_admin_keuangan_faktur_collectors: [],
            mean_admin_keuangan_fakturs: [],
            admin_gudangs: [],
            admin_gudang_surat_jalan_keluar: [],
            admin_gudang_tonase_keluar: [],
            admin_gudang_surat_jalan_masuk: [],
            admin_gudang_tonase_masuk: [],
            mean_admin_gudang_surat_jalan_keluar: [],
            mean_admin_gudang_tonase_keluar: [],
            mean_admin_gudang_surat_jalan_masuk: [],
            mean_admin_gudang_tonase_masuk: [],
            headers:[
                {
                    text: 'Year',
                    align: 'start',
                    value: 'tahun', 
                    align:'center',
                    groupable: false
                },
                { text: 'Month', value: 'Month' , align:'center', groupable: false},
                { text: 'Kategori', value: 'nama_kategori' , align:'center', groupable: false},
                { text: 'Total', value: 'total' , align:'center', groupable: false},
                // { text: '', value: 'action' , align:'center', groupable: false}
            ],
            kpi_admins: [],
            header_details:[
                {
                    text: 'Region',
                    align: 'start',
                    value: 'region', 
                    align:'center',
                    groupable: false
                },
                { text: 'Office', value: 'office' , align:'center', groupable: false},
                {
                    text: 'Year',
                    value: 'tahun', 
                    align:'center',
                    groupable: false
                },
                { text: 'Month', value: 'Month' , align:'center', groupable: false},
                { text: 'Bagian', value: 'nama_bagian' , align:'center', groupable: false},
                { text: 'Kategori', value: 'nama_kategori' , align:'center', groupable: false},
                { text: 'Total', value: 'total' , align:'center', groupable: false},
            ],
            headersColumn: {
                'Region': 'region',
                'Year': 'tahun',
                'Month': 'Month',
                'Office': 'office',
                'Bagian': 'nama_bagian',
                'Kategori': 'nama_kategori',
                'Total': 'total',
            },
            kpi_details: [],
            dialogDetail: false
        }
    },
    mounted(){
        this.$store.dispatch('setOverlay', false)
        this.getRegion()
        this.getOffice()
        this.getBagian()
        this.getYear()
    },
    methods:{ 
        clear(){
            this.office = ''
            this.date = ''
            this.bagian = ''
            this.date_to = ''
        },  
        setOfficeName(value){
            if (value === null || value === '') {
                this.labelText = ''
            } else {
                var temp = this.offices.find(({ office_id }) => office_id === this.office);
                this.labelText = ` - ${temp.office.trim()}`
            }
                
        },
        async getRegion(){
            const respVar = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KMB&appl_id=WEBKMB&var_id=REGION&groupid=${this.$store.state.kmb.group_id}`, null, false, false, false)

            if (respVar.data.data != null) {
                this.region = respVar.data.data
                this.disabled_region = true
            }

            const respData = await backendApi.fetchCore(`/v2/master/kmb/region?region=${this.region ? this.region : ''}`, null, false, false, false)
            if (respData.status === 200) {
                this.regions = respData.data
            }
        },
        async getOffice(event){
            if (event === null || event === '') {
                this.offices = []
                this.office = ''
                this.labelText = ''
            } else {

                const respVar = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KMB&appl_id=WEBKMB&var_id=BRANCH&groupid=${this.$store.state.kmb.group_id}`, null, false, false, false)

                if (respVar.data.data != null) {
                    this.office = respVar.data.data
                    this.disabled_office = true   
                    this.disabled_region = true
                }

                this.offices = []
                const respData = await backendApi.fetchCore(`/v2/master/kmb/office?region=${this.region ? this.region : ''}&office_id=${this.office ? this.office : ''}`, null, false, false, false)
                if (respData.status === 200) {
                    this.offices = respData.data

                    var temp = this.offices.find(({ office_id }) => office_id === this.office);
                    this.labelText = ` - ${temp.office.trim()}`
                }
            }
            
        },
        async getBagian(event){
            if (event === null || event === '') {
                this.bagians = []
                this.bagian = ''
            } else {
                this.bagians = []
                const respData = await backendApi.fetchCore(`/v2/master/kmb/kpi_admin`, null, false, false, false)
                if (respData.status === 200) {
                    this.bagians = respData.data
                }
            }
        },
        async getYear(){
            this.tahuns = []
            const respData = await backendApi.fetchCore(`/v2/master/kmb/kpi_admin/getYear`, null, false, false, false)
            if (respData.status === 200) {
                this.tahuns = respData.data
            }
        },
        monthNumToName(monthnum) {
            return this.months[monthnum - 1] || '';
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            console.log(e);
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        getChartAdminPenjualan(dt1, dt2, dt3, dt4, dt5, container, means_admin_penjualan_databases, means_admin_penjualan_surat_pesanans, means_admin_penjualan_items, means_admin_penjualan_surat_jalans, means_admin_penjualan_confirm_orders){

            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    labelFormatter: this.addSymbols
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    maxWidth: 200,
                    itemMaxWidth: 150,
                    fontSize: 12,
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    horizontalAlign: "right",
                    verticalAlign: "center",
                },
                data: [
                {
                    type: "line",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#2d4059",
                    name: "Customer Baru",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    legendText: "Avg Customer Baru : " + this.$store.getters.convertToCurrencyUs(means_admin_penjualan_databases),
                    color: "#2d4059",
                    click: this.getDetail
                    // yValueFormatString: "#,###,,,.##",
                },
                {
                    type: "line",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#ed6663",
                    name: "Surat Pesanan",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#2d4059",
                    color: "#ed6663",
                    click: this.getDetail,
                    legendText: "Avg Surat Pesanan : " + this.$store.getters.convertToCurrencyUs(means_admin_penjualan_surat_pesanans),
                    // yValueFormatString: "#,###,,,.##",
                },
                {
                    type: "line",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#2596be",
                    name: "Item Surat Pesanan",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#2d4059",
                    color: "#2596be",
                    click: this.getDetail,
                    legendText: "Avg Item Surat Pesanan : " + this.$store.getters.convertToCurrencyUs(means_admin_penjualan_items),
                    // yValueFormatString: "#,###,,,.##",
                },
                {
                    type: "line",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#49be25",
                    name: "Surat Jalan kembali",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#2d4059",
                    color: "#49be25",
                    click: this.getDetail,
                    legendText: "Avg Surat Jalan kembali : " + this.$store.getters.convertToCurrencyUs(means_admin_penjualan_surat_jalans),
                    // yValueFormatString: "#,###,,,.##",
                },
                {
                    type: "line",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#f7e209",
                    name: "Konfirmasi Order",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#2d4059",
                    color: "#f7e209",
                    click: this.getDetail,
                    legendText: "Avg Konfirmasi Order : " + this.$store.getters.convertToCurrencyUs(means_admin_penjualan_confirm_orders),
                    // yValueFormatString: "#,###,,,.##",
                }
                ]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;
            chart.options.data[2].dataPoints = dt3;
            chart.options.data[3].dataPoints = dt4;
            chart.options.data[4].dataPoints = dt5;


            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        getChartAdminKeuangan(dt1, dt2, dt3, dt4, dt5, dt6, container, means_admin_keuangan_lunas, means_admin_keuangan_rupiahs, means_admin_keuangan_kuitansi, means_admin_keuangan_tanda_terimas, means_admin_keuangan_faktur_collectors, means_admin_keuangan_fakturs){

            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    maxWidth: 200,
                    itemMaxWidth: 150,
                    fontSize: 12,
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    horizontalAlign: "right",
                    verticalAlign: "center",
                },
                data: [
                {
                    type: "line",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#2d4059",
                    name: "Faktur Lunas (Lembar)",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    color: "#2d4059",
                    click: this.getDetail,
                    legendText: "Avg Faktur Lunas (Lembar) : " + this.$store.getters.convertToCurrencyUs(means_admin_keuangan_lunas),
                    // yValueFormatString: "#,###,,,.##",
                },
                {
                    type: "line",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#ed6663",
                    name: "Faktur Lunas (Rupiah)",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "horizontal",
                    // color: "#2d4059",
                    color: "#ed6663",
                    click: this.getDetail,
                    yValueFormatString: "#,##0.##\" 100Juta\"",
                    legendText: "Avg Faktur Lunas (Rupiah) : " + this.$store.getters.convertToCurrencyUs(means_admin_keuangan_rupiahs),
                    // yValueFormatString: "#,###,,,.##",
                },
                {
                    type: "line",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#2596be",
                    name: "Uang Tunai (Rupiah)",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "horizontal",
                    // color: "#2d4059",
                    color: "#2596be",
                    click: this.getDetail,
                    yValueFormatString: "#,##0.##\" 100Juta\"",
                    legendText: "Avg Uang Tunai (Rupiah) : " + this.$store.getters.convertToCurrencyUs(means_admin_keuangan_kuitansi),
                    // yValueFormatString: "#,###,,,.##",
                },
                {
                    type: "line",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#49be25",
                    name: "Tanda Terima Faktur",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#2d4059",
                    color: "#49be25",
                    click: this.getDetail,
                    legendText: "Avg Tanda Terima Faktur : " + this.$store.getters.convertToCurrencyUs(means_admin_keuangan_tanda_terimas),
                    // yValueFormatString: "#,###,,,.##",
                },
                {
                    type: "line",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#f7e209",
                    name: "Faktur dibawa Kolektor",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#2d4059",
                    color: "#f7e209",
                    click: this.getDetail,
                    legendText: "Avg Faktur dibawa Kolektor : " + this.$store.getters.convertToCurrencyUs(means_admin_keuangan_faktur_collectors),
                    // yValueFormatString: "#,###,,,.##",
                },
                {
                    type: "line",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#873e23",
                    name: "Faktur yang di create cabang",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#2d4059",
                    color: "#873e23",
                    click: this.getDetail,
                    legendText: "Avg Faktur yang di create cabang : " + this.$store.getters.convertToCurrencyUs(means_admin_keuangan_fakturs),
                    // yValueFormatString: "#,###,,,.##",
                }
                ]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;
            chart.options.data[2].dataPoints = dt3;
            chart.options.data[3].dataPoints = dt4;
            chart.options.data[4].dataPoints = dt5;
            chart.options.data[5].dataPoints = dt6;

            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        getChartAdminGudang(dt1, dt2, dt3, dt4, container, means_admin_gudang_surat_jalan_keluar, means_admin_gudang_tonase_keluar, means_admin_gudang_surat_jalan_masuk, means_admin_gudang_tonase_masuk){

            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    maxWidth: 200,
                    itemMaxWidth: 150,
                    fontSize: 12,
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    horizontalAlign: "right",
                    verticalAlign: "center",
                },
                data: [
                {
                    type: "line",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#2d4059",
                    name: "Surat Jalan keluar (Qty)",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    color: "#2d4059",
                    click: this.getDetail,
                    legendText: "Avg Surat Jalan keluar (Qty) : " + this.$store.getters.convertToCurrencyUs(means_admin_gudang_surat_jalan_keluar),
                    // yValueFormatString: "#,###,,,.##",
                },
                {
                    type: "line",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#ed6663",
                    name: "Surat Jalan keluar (TON)",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#2d4059",
                    color: "#ed6663",
                    click: this.getDetail,
                    yValueFormatString: "#,##0.##\" TON\"",
                    legendText: "Avg Surat Jalan keluar (TON) : " + this.$store.getters.convertToCurrencyUs(means_admin_gudang_tonase_keluar / 1000) + ' TON',
                    // yValueFormatString: "#,###,,,.##",
                },
                {
                    type: "line",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#2596be",
                    name: "Surat Jalan masuk (Qty)",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#2d4059",
                    click: this.getDetail,
                    color: "#2596be",
                    legendText: "Avg Surat Jalan masuk (Qty) : " + this.$store.getters.convertToCurrencyUs(means_admin_gudang_surat_jalan_masuk),
                    // yValueFormatString: "#,###,,,.##",
                },
                {
                    type: "line",
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#49be25",
                    name: "Surat Jalan masuk (TON)",
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#2d4059",
                    color: "#49be25",
                    click: this.getDetail,
                    yValueFormatString: "#,##0.##\" TON\"",
                    legendText: "Avg Surat Jalan masuk (TON) : " + this.$store.getters.convertToCurrencyUs(means_admin_gudang_tonase_masuk / 1000) + ' TON',

                    // yValueFormatString: "#,###,,,.##",
                }
                ]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;
            chart.options.data[2].dataPoints = dt3;
            chart.options.data[3].dataPoints = dt4;

            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        async getKpiAdmin(){
            this.$store.dispatch('setOverlay', true)
            this.dialog = false
            // console.log(this.offices.find(({ office_id }) => office_id === this.office));
            //Admin Penjualan
            this.admin_penjualan_databases = []
            this.admin_penjualan_surat_pesanans = []
            this.admin_penjualan_items = []
            this.admin_penjualan_surat_jalans = []
            this.admin_penjualan_confirm_orders = []

            //Mean Admin Penjualan
            this.mean_admin_penjualan_databases = []
            this.mean_admin_penjualan_surat_pesanans = []
            this.mean_admin_penjualan_items = []
            this.mean_admin_penjualan_surat_jalans = []
            this.mean_admin_penjualan_confirm_orders = []

            //Admin Keuangan
            this.admin_keuangan_lunas = []
            this.admin_keuangan_rupiahs = []
            this.admin_keuangan_kuitansi = []
            this.admin_keuangan_tanda_terimas = []
            this.admin_keuangan_faktur_collectors = []
            this.admin_keuangan_fakturs = []

            //Mean Admin Keuangan
            this.mean_admin_keuangan_lunas = []
            this.mean_admin_keuangan_rupiahs = []
            this.mean_admin_keuangan_kuitansi = []
            this.mean_admin_keuangan_tanda_terimas = []
            this.mean_admin_keuangan_faktur_collectors = []
            this.mean_admin_keuangan_fakturs = []

            //Admin Gudang
            this.admin_gudang_surat_jalan_keluar = []
            this.admin_gudang_tonase_keluar = []
            this.admin_gudang_surat_jalan_masuk = []
            this.admin_gudang_tonase_masuk = []

            //Mean Admin Gudang
            this.mean_admin_gudang_surat_jalan_keluar = []
            this.mean_admin_gudang_tonase_keluar = []
            this.mean_admin_gudang_surat_jalan_masuk = []
            this.mean_admin_gudang_tonase_masuk = []

            this.kpi_admins = []

            var date_from = this.date ? new Date(this.date).toISOString().substr(0, 7).replace(/-/g,"") : ''
            var date_to = this.date_to ? new Date(this.date_to).toISOString().substr(0, 7).replace(/-/g,"") : ''

            const respData = await backendApi.fetchCore(`/v2/kmb/kpi_admin?region=${this.region ? this.region : ''}&office=${this.office ? this.office : ''}&tahun=${this.tahun ? this.tahun : ''}&bagian=${this.bagian ? this.bagian : ''}&dateFrom=${date_from}&dateTo=${date_to}`, null, false, false, false)
            if (respData.status === 200) {

                this.$store.dispatch('setOverlay', false)

                this.kpi_admins = respData.data

                for (let index = 0; index < respData.data.length; index++) {
                    if (respData.data[index].nama_bagian === 'ADMIN PENJUALAN') {
                        if (respData.data[index].kategori === '1') {
                            this.admin_penjualan_databases.push({
                                label: respData.data[index].tahun.trim() + '-' + this.monthNumToName(parseInt(respData.data[index].Month)), 
                                y: parseFloat(respData.data[index].total),
                                tahun: respData.data[index].tahun.trim(),
                                Month: respData.data[index].Month,
                                kategori: respData.data[index].kategori,
                                bagian: respData.data[index].bagian,
                            });

                            this.mean_admin_penjualan_databases.push(parseFloat(respData.data[index].total))

                        }
                        if (respData.data[index].kategori === '2') {
                            this.admin_penjualan_surat_pesanans.push({
                                label: respData.data[index].tahun.trim() + '-' + this.monthNumToName(parseInt(respData.data[index].Month)), 
                                y: parseFloat(respData.data[index].total),
                                tahun: respData.data[index].tahun.trim(),
                                Month: respData.data[index].Month,
                                kategori: respData.data[index].kategori,
                                bagian: respData.data[index].bagian,
                            });

                            this.mean_admin_penjualan_surat_pesanans.push(parseFloat(respData.data[index].total))
                        }
                        if (respData.data[index].kategori === '3') {
                            this.admin_penjualan_items.push({
                                label: respData.data[index].tahun.trim() + '-' + this.monthNumToName(parseInt(respData.data[index].Month)), 
                                y: parseFloat(respData.data[index].total),
                                tahun: respData.data[index].tahun.trim(),
                                Month: respData.data[index].Month,
                                kategori: respData.data[index].kategori,
                                bagian: respData.data[index].bagian,
                            });

                            this.mean_admin_penjualan_items.push(parseFloat(respData.data[index].total))

                        }
                        if (respData.data[index].kategori === '4') {
                            this.admin_penjualan_surat_jalans.push({
                                label: respData.data[index].tahun.trim() + '-' + this.monthNumToName(parseInt(respData.data[index].Month)), 
                                y: parseFloat(respData.data[index].total),
                                tahun: respData.data[index].tahun.trim(),
                                Month: respData.data[index].Month,
                                kategori: respData.data[index].kategori,
                                bagian: respData.data[index].bagian,
                            });

                            this.mean_admin_penjualan_surat_jalans.push(parseFloat(respData.data[index].total))
                        }
                        if (respData.data[index].kategori === '5') {
                            this.admin_penjualan_confirm_orders.push({
                                label: respData.data[index].tahun.trim() + '-' + this.monthNumToName(parseInt(respData.data[index].Month)), 
                                y: parseFloat(respData.data[index].total),
                                tahun: respData.data[index].tahun.trim(),
                                Month: respData.data[index].Month,
                                kategori: respData.data[index].kategori,
                                bagian: respData.data[index].bagian,
                            });

                            this.mean_admin_penjualan_confirm_orders.push(parseFloat(respData.data[index].total))

                        }
                    } 
                    if (respData.data[index].nama_bagian === 'ADMIN KEUANGAN') {
                        if (respData.data[index].kategori === '1') {
                            this.admin_keuangan_lunas.push({
                                label: respData.data[index].tahun.trim() + '-' + this.monthNumToName(parseInt(respData.data[index].Month)), 
                                y: parseFloat(respData.data[index].total),
                                tahun: respData.data[index].tahun.trim(),
                                Month: respData.data[index].Month,
                                kategori: respData.data[index].kategori,
                                bagian: respData.data[index].bagian,
                            });

                            this.mean_admin_keuangan_lunas.push(parseFloat(respData.data[index].total))

                        }
                        if (respData.data[index].kategori === '2') {
                            this.admin_keuangan_rupiahs.push({
                                label: respData.data[index].tahun.trim() + '-' + this.monthNumToName(parseInt(respData.data[index].Month)), 
                                y: parseFloat(respData.data[index].total / 100000000),
                                tahun: respData.data[index].tahun.trim(),
                                Month: respData.data[index].Month,
                                kategori: respData.data[index].kategori,
                                bagian: respData.data[index].bagian,
                            });

                            this.mean_admin_keuangan_rupiahs.push(parseFloat(respData.data[index].total))

                        }
                        if (respData.data[index].kategori === '3') {
                            this.admin_keuangan_kuitansi.push({
                                label: respData.data[index].tahun.trim() + '-' + this.monthNumToName(parseInt(respData.data[index].Month)), 
                                y: parseFloat(respData.data[index].total / 100000000),
                                tahun: respData.data[index].tahun.trim(),
                                Month: respData.data[index].Month,
                                kategori: respData.data[index].kategori,
                                bagian: respData.data[index].bagian,
                            });

                            this.mean_admin_keuangan_kuitansi.push(parseFloat(respData.data[index].total))

                        }
                        if (respData.data[index].kategori === '4') {
                            this.admin_keuangan_tanda_terimas.push({
                                label: respData.data[index].tahun.trim() + '-' + this.monthNumToName(parseInt(respData.data[index].Month)), 
                                y: parseFloat(respData.data[index].total),
                                tahun: respData.data[index].tahun.trim(),
                                Month: respData.data[index].Month,
                                kategori: respData.data[index].kategori,
                                bagian: respData.data[index].bagian,
                            });

                            this.mean_admin_keuangan_tanda_terimas.push(parseFloat(respData.data[index].total))

                        }
                        if (respData.data[index].kategori === '5') {
                            this.admin_keuangan_faktur_collectors.push({
                                label: respData.data[index].tahun.trim() + '-' + this.monthNumToName(parseInt(respData.data[index].Month)), 
                                y: parseFloat(respData.data[index].total),
                                tahun: respData.data[index].tahun.trim(),
                                Month: respData.data[index].Month,
                                kategori: respData.data[index].kategori,
                                bagian: respData.data[index].bagian,
                            });

                            this.mean_admin_keuangan_faktur_collectors.push(parseFloat(respData.data[index].total))

                        }
                        if (respData.data[index].kategori === '6') {
                            this.admin_keuangan_fakturs.push({
                                label: respData.data[index].tahun.trim() + '-' + this.monthNumToName(parseInt(respData.data[index].Month)), 
                                y: parseFloat(respData.data[index].total),
                                tahun: respData.data[index].tahun.trim(),
                                Month: respData.data[index].Month,
                                kategori: respData.data[index].kategori,
                                bagian: respData.data[index].bagian,
                            });

                            this.mean_admin_keuangan_fakturs.push(parseFloat(respData.data[index].total))

                        }
                    } 
                    if (respData.data[index].nama_bagian === 'ADMIN GUDANG') {
                        if (respData.data[index].kategori === '1') {
                            this.admin_gudang_surat_jalan_keluar.push({
                                label: respData.data[index].tahun.trim() + '-' + this.monthNumToName(parseInt(respData.data[index].Month)), 
                                y: parseFloat(respData.data[index].total),
                                tahun: respData.data[index].tahun.trim(),
                                Month: respData.data[index].Month,
                                kategori: respData.data[index].kategori,
                                bagian: respData.data[index].bagian,
                            });

                            this.mean_admin_gudang_surat_jalan_keluar.push(parseFloat(respData.data[index].total))

                        }
                        if (respData.data[index].kategori === '2') {
                            this.admin_gudang_tonase_keluar.push({
                                label: respData.data[index].tahun.trim() + '-' + this.monthNumToName(parseInt(respData.data[index].Month)), 
                                y: parseFloat(respData.data[index].total / 1000) ,
                                tahun: respData.data[index].tahun.trim(),
                                Month: respData.data[index].Month,
                                kategori: respData.data[index].kategori,
                                bagian: respData.data[index].bagian,
                            });

                            this.mean_admin_gudang_tonase_keluar.push(parseFloat(respData.data[index].total))

                        }
                        if (respData.data[index].kategori === '3') {
                            this.admin_gudang_surat_jalan_masuk.push({
                                label: respData.data[index].tahun.trim() + '-' + this.monthNumToName(parseInt(respData.data[index].Month)), 
                                y: parseFloat(respData.data[index].total),
                                tahun: respData.data[index].tahun.trim(),
                                Month: respData.data[index].Month,
                                kategori: respData.data[index].kategori,
                                bagian: respData.data[index].bagian,
                            });

                            this.mean_admin_gudang_surat_jalan_masuk.push(parseFloat(respData.data[index].total))

                        }
                        if (respData.data[index].kategori === '4') {
                            this.admin_gudang_tonase_masuk.push({
                                label: respData.data[index].tahun.trim() + '-' + this.monthNumToName(parseInt(respData.data[index].Month)), 
                                y: parseFloat(respData.data[index].total / 1000) ,
                                tahun: respData.data[index].tahun.trim(),
                                Month: respData.data[index].Month,
                                kategori: respData.data[index].kategori,
                                bagian: respData.data[index].bagian,
                            });

                            this.mean_admin_gudang_tonase_masuk.push(parseFloat(respData.data[index].total))

                        }
                    }   
                }

                //Mean Admin Penjualan
                var means_admin_penjualan_databases = this.calculateMeanArray(this.mean_admin_penjualan_databases, this.mean_admin_penjualan_databases.length)
                var means_admin_penjualan_surat_pesanans = this.calculateMeanArray(this.mean_admin_penjualan_surat_pesanans, this.mean_admin_penjualan_surat_pesanans.length)
                var means_admin_penjualan_items = this.calculateMeanArray(this.mean_admin_penjualan_items, this.mean_admin_penjualan_items.length)
                var means_admin_penjualan_surat_jalans = this.calculateMeanArray(this.mean_admin_penjualan_surat_jalans, this.mean_admin_penjualan_surat_jalans.length)
                var means_admin_penjualan_confirm_orders = this.calculateMeanArray(this.mean_admin_penjualan_confirm_orders, this.mean_admin_penjualan_confirm_orders.length)

                //Mean Admin Keuangan
                var means_admin_keuangan_lunas = this.calculateMeanArray(this.mean_admin_keuangan_lunas, this.mean_admin_keuangan_lunas.length)
                var means_admin_keuangan_rupiahs = this.calculateMeanArray(this.mean_admin_keuangan_rupiahs, this.mean_admin_keuangan_rupiahs.length)
                var means_admin_keuangan_kuitansi = this.calculateMeanArray(this.mean_admin_keuangan_kuitansi, this.mean_admin_keuangan_kuitansi.length)
                var means_admin_keuangan_tanda_terimas = this.calculateMeanArray(this.mean_admin_keuangan_tanda_terimas, this.mean_admin_keuangan_tanda_terimas.length)
                var means_admin_keuangan_faktur_collectors = this.calculateMeanArray(this.mean_admin_keuangan_faktur_collectors, this.mean_admin_keuangan_faktur_collectors.length)
                var means_admin_keuangan_fakturs = this.calculateMeanArray(this.mean_admin_keuangan_fakturs, this.mean_admin_keuangan_fakturs.length)

                //Mean Admin Gudang
                var means_admin_gudang_surat_jalan_keluar = this.calculateMeanArray(this.mean_admin_gudang_surat_jalan_keluar, this.mean_admin_gudang_surat_jalan_keluar.length)
                var means_admin_gudang_tonase_keluar = this.calculateMeanArray(this.mean_admin_gudang_tonase_keluar, this.mean_admin_gudang_tonase_keluar.length)
                var means_admin_gudang_surat_jalan_masuk = this.calculateMeanArray(this.mean_admin_gudang_surat_jalan_masuk, this.mean_admin_gudang_surat_jalan_masuk.length)
                var means_admin_gudang_tonase_masuk = this.calculateMeanArray(this.mean_admin_gudang_tonase_masuk, this.mean_admin_gudang_tonase_masuk.length)

                this.getChartAdminPenjualan(this.admin_penjualan_databases, this.admin_penjualan_surat_pesanans, this.admin_penjualan_items, this.admin_penjualan_surat_jalans, this.admin_penjualan_confirm_orders, "container1", means_admin_penjualan_databases, means_admin_penjualan_surat_pesanans, means_admin_penjualan_items, means_admin_penjualan_surat_jalans, means_admin_penjualan_confirm_orders)

                this.getChartAdminKeuangan(this.admin_keuangan_lunas, this.admin_keuangan_rupiahs, this.admin_keuangan_kuitansi, this.admin_keuangan_tanda_terimas, this.admin_keuangan_faktur_collectors, this.admin_keuangan_fakturs, "container2", means_admin_keuangan_lunas, means_admin_keuangan_rupiahs, means_admin_keuangan_kuitansi, means_admin_keuangan_tanda_terimas, means_admin_keuangan_faktur_collectors, means_admin_keuangan_fakturs)

                this.getChartAdminGudang(this.admin_gudang_surat_jalan_keluar, this.admin_gudang_tonase_keluar, this.admin_gudang_surat_jalan_masuk, this.admin_gudang_tonase_masuk, "container3", means_admin_gudang_surat_jalan_keluar, means_admin_gudang_tonase_keluar, means_admin_gudang_surat_jalan_masuk, means_admin_gudang_tonase_masuk)
            }
        },
        async getDetail(item){
            this.getDetailItem(item.dataPoint)
        },
        async getDetailItem(item){
            this.dialogDetail = true
            this.loading = true
            this.kpi_details = []
            const respData = await backendApi.fetchCore(`/v2/kmb/kpi_admin/show?region=${this.region ? this.region : ''}&office=${this.office ? this.office : ''}&bagian=${item.bagian}&kategori=${item.kategori}&tahun=${item.tahun}&Month=${item.Month}`, null, false, false, false)
            if (respData.status === 200) {
                this.loading = false
                this.kpi_details = respData.data
            }
        },
        calculateMeanArray(arrays, total_array){
            var mean = arrays.reduce((a, b) => a + b, 0) / total_array

            return mean
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
        async exportExcel(){
            var date_from = this.date ? new Date(this.date).toISOString().substr(0, 7).replace(/-/g,"") : ''
            var date_to = this.date_to ? new Date(this.date_to).toISOString().substr(0, 7).replace(/-/g,"") : ''
            const respData = await backendApi.fetchCore(`/v2/kmb/kpi_admin/export?region=${this.region ? this.region : ''}&office=${this.office ? this.office : ''}&tahun=${this.tahun ? this.tahun : ''}&bagian=${this.bagian ? this.bagian : ''}&dateFrom=${date_from}&dateTo=${date_to}`, null, false, false, false)
            if (respData.status === 200) {

                this.$store.dispatch('setOverlay', false)

                return respData.data
            }
        }
    },
    watch: {
    }
}
</script>